export default [
  {
    header: 'Quản lý',
  },
  {
    title: 'Trang giới thiệu',
    icon: 'ShoppingCartIcon',
    route: 'manage-page-info',
  },
  {
    title: 'Sản phẩm',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-product-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'manage-product-add' },
      },
    ],
  },
  {
    title: 'Danh mục',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-category-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'manage-category-add' },
      },
    ],
  },
  {
    title: 'Tin tức',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-post-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'manage-post-add' },
      },
    ],
  },
  {
    title: 'Nhật ký sản xuất',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-nksx-list',
      },
      // {
      //   title: 'Thêm mới',
      //   route: { name: 'manage-nksx-add' },
      // },
    ],
  },
  {
    title: 'Hướng dẫn kỹ thuật',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-hdkt-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'manage-hdkt-add' },
      },
    ],
  },
  {
    title: 'Thành tựu NCKH',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-nckh-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'manage-nckh-add' },
      },
    ],
  },
  {
    title: 'Banner sản phẩm',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-banner-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'manage-banner-add' },
      },
    ],
  },
  {
    title: 'Yêu cầu tư vấn',
    route: 'manage-support-list',
    icon: 'CalendarIcon',
  },
  {
    title: 'Upload file',
    route: 'manage-upload-list',
    icon: 'FileTextIcon',
  },
]
